import { cva } from "class-variance-authority";

export const buttonVariants = cva(
  "flex items-center justify-center gap-2 font-semibold rounded-full text-sm shrink-0",
  {
    variants: {
      variant: {
        filled: "px-6 py-3.5",
        outlined: "border px-6 py-3.5",
        ghost: "p-3.5",
        filledTonal: "px-6 py-3.5",
        destructive: "px-6 py-3.5",
      },
      disabled: {
        true: "cursor-not-allowed",
      },
    },
    compoundVariants: [
      {
        variant: "filled",
        disabled: false,
        class:
          "bg-primary text-on-primary hover:bg-primary/[92%] focus:bg-primary/[88%]",
      },
      {
        variant: "filled",
        disabled: true,
        class: "bg-on-surface/[0.12] text-on-surface/40",
      },
      {
        variant: "outlined",
        disabled: false,
        class:
          "hover:bg-on-surface/10 focus:bg-on-surface/20 text-primary border-outline",
      },
      {
        variant: "outlined",
        disabled: true,
        class: "text-on-surface/40 border-on-surface/[0.12]",
      },
      {
        variant: "ghost",
        disabled: false,
        class: "hover:bg-on-surface/10 focus:bg-on-surface/20 text-primary",
      },
      {
        variant: "ghost",
        disabled: true,
        class: "text-on-surface/40",
      },
      {
        variant: "filledTonal",
        disabled: false,
        class:
          "bg-secondary-container text-on-secondary-container hover:bg-secondary-container/80 focus:bg-secondary-container/80",
      },
      {
        variant: "filledTonal",
        disabled: true,
        class:
          "bg-on-secondary-container/[0.12] text-on-secondary-container/40",
      },
      {
        variant: "destructive",
        disabled: false,
        class: "bg-error text-on-error hover:bg-error/80 focus:bg-error/80",
      },
      {
        variant: "destructive",
        disabled: true,
        class: "bg-on-error/[0.12] text-on-error/40",
      },
    ],
    defaultVariants: {
      variant: "filled",
      disabled: false,
    },
  },
);
